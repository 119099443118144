
const validateEpisode = episode => {
    const warnings = []
    if (episode.duration < 20) {
        warnings.push("Episode too short")
    }

    if (episode.title === 'undefined') {
        warnings.push("Episode title invalid")
    }

    if (episode.description === 'undefined') {
        warnings.push("Episode description invalid")
    }

    return warnings
}

export { validateEpisode };