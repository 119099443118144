import React, { useState, useEffect } from "react";

import { Button } from "reactstrap";

import { Link } from "react-router-dom";

import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Loading from './Loading.js'

import { getSeriesList, compact } from "../utils/seriesApi";

import { useAuth0 } from "@auth0/auth0-react";

function SeriesList() {

    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    const {
        getAccessTokenSilently
    } = useAuth0();

    const compactAll = async() => {
        try {
            setIsLoaded(false);
            await compact(token);
            await fetchSeries();
        } catch(error) {
            setError(error);
        }
    }

    const fetchSeries = async() => {
        const token = await getAccessTokenSilently();
        setToken(token);
        try {
            const series = await getSeriesList(token);
            setIsLoaded(true);
            setItems(series);
        } catch(error) {
            setIsLoaded(false);
            setError(error);
        }
    }

    useEffect(() => {
        fetchSeries()
    }, []);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="next-steps my-5">
              <Row className="d-flex justify-content-between">
                {items.map((col, i) => (
                    <Col key={i} md={5} className="mb-4">
                    <h6 className="mb-3">
                        <Link to={{pathname: `/series/${col.series}`}}>
                            <FontAwesomeIcon icon="link" className="mr-2" />
                            {col.series}
                        </Link>
                    </h6>
                    <p>{col.series}</p>
                    </Col>
                ))}
                </Row>
                <hr />
                <div className='compact'>
                    <Button color="danger" onClick={() => {
                            compactAll();
                    }}>Compact</Button>
                    <Link to="/invalid-episodes">
                        <Button color="warning">Invalid Episodes</Button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default SeriesList;
