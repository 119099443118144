import React, { useState, useEffect } from "react";
import Moment from 'react-moment';
import { Alert, Button, Breadcrumb, BreadcrumbItem } from "reactstrap";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getSeasonDetail, deleteEpisode as delete0 } from "../utils/seriesApi";
import { validateEpisode } from "../utils/episodeValidator";

export const SeasonDetailComponent = () => {

  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [season, setSeason] = useState([]);
  const {seriesId, seasonId} = useParams("seriesId", "seasonId");

  const {
      getAccessTokenSilently
  } = useAuth0();

  const deleteEpisode = async (episodeId) => {
    try {
        setIsLoaded(false);
        await delete0(token, seriesId, seasonId, episodeId);
        await fetchSeason();
    } catch(error) {
        setError(error);
    }
}

  const fetchSeason = async() => {
    const token = await getAccessTokenSilently();
    setToken(token);
    try {
        const season = await getSeasonDetail(token, seriesId, seasonId);

        season.episodes.forEach(episode => {
          const warnings = validateEpisode(episode)
          if (warnings.length > 0) {
            episode.warnings = warnings
          }
        })

        setSeason(season);
        setIsLoaded(true);
    } catch(error) {
        setIsLoaded(false);
        setError(error);
    }
  }

  useEffect(() => {
      fetchSeason();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
      return <Loading />;
  } else {

    const maybeAlert = episode => {
      if (episode.warnings) {
        return (
          <div>
          {episode.warnings.map((warning, i) => (
            <Alert color="danger">{warning}</Alert>
          ))}
          </div>
        )
      }
    }

    return (
      <>
        <div className="mb-5">
          <h1>{seriesId}, {seasonId}</h1>
          <h4>{season.episodes.length} Episodes</h4>
        </div>

        <div>
          <Breadcrumb>
            <BreadcrumbItem tag="a" href="/series" >Kids' Shows</BreadcrumbItem>
            <BreadcrumbItem tag="a" href={`/series/${seriesId}`} >{seriesId}</BreadcrumbItem>
            <BreadcrumbItem active>{seasonId}</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div className="result-block-container">
            <div className="result-block" data-testid="api-result">
              <div className="next-steps my-5">
                <Row className="d-flex justify-content-between">
                  {season.episodes.map((col, i) => (
                      <Col key={i} md={5} className="mb-4">
                        <h6 className="mb-3">
                            <FontAwesomeIcon className="mr-2" />
                            {col.title}
                            {maybeAlert(col)}
                        </h6>
                        <p>{Math.round((col.duration / 60) * 10) / 10} minutes</p>
                        <p>{col.description}</p>
                        <p>{col.filename}</p>
                        <p><Moment format="M/D/YY">{col.createdDate}</Moment></p>
                        <Button color="danger" onClick={() => {
                          deleteEpisode(col.episodeId);
                        }}>Delete</Button>
                      </Col>
                  ))}
                  </Row>
              </div>
            </div>
          
        </div>
      </>
    );
  }  
};

export default withAuthenticationRequired(SeasonDetailComponent, {
  onRedirecting: () => <Loading />
});