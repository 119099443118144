import React, { useState, useContext, useEffect } from "react";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";
import Moment from 'react-moment';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getInvalidEpisodes, deleteEpisode as delete0 } from "../utils/seriesApi";

import { Link } from "react-router-dom";

export const InvalidEpisodeComponent = () => {

  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [invalidEpisodes, setInvalidEpisodes] = useState([]);

  const {
      getAccessTokenSilently
  } = useAuth0();

  const fetchInvalidEpisodes = async() => {
    const token = await getAccessTokenSilently();
    setToken(token);
    try {
        const series = await getInvalidEpisodes(token);
        setInvalidEpisodes(series);
        setIsLoaded(true);
    } catch(error) {
        setIsLoaded(false);
        setError(error);
    }
}

  useEffect(() => {
      fetchInvalidEpisodes()
  }, []);

  const deleteEpisode = async (seriesId, seasonId, episodeId) => {
    try {
        setIsLoaded(false);
        await delete0(token, seriesId, seasonId, episodeId);
        await fetchInvalidEpisodes();
    } catch(error) {
        setError(error);
    }
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="mb-5">
          <h1>Invalid Episodes</h1>
        </div>

        <div>
          <Breadcrumb>
            <BreadcrumbItem tag="a" href="/series" >Kids' Shows</BreadcrumbItem>
            <BreadcrumbItem active>Invalid Episodes</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div className="result-block-container">
          
            <div className="result-block" data-testid="api-result">
              <div className="next-steps my-5">
                <Row className="d-flex justify-content-between">
                  {invalidEpisodes.map((col, i) => (
                      <Col key={i} md={5} className="mb-4">
                        <h6 className="mb-3">
                            <FontAwesomeIcon className="mr-2" />
                            {col.title}
                        </h6>
                        <p>{Math.round((col.duration / 60) * 10) / 10} minutes</p>
                        <p>{col.description}</p>
                        <p>{col.filename}</p>
                        <p><Moment format="M/D/YY">{col.createdDate}</Moment></p>
                        <Button color="danger" onClick={() => {
                          deleteEpisode(col.series, col.season, col.episodeId);
                        }}>Delete</Button>
                      </Col>
                  ))}
                  </Row>
              </div>
            </div>
          
        </div>
      </>
    );
  }  
};

export default withAuthenticationRequired(InvalidEpisodeComponent, {
    onRedirecting: () => <Loading />,
  });