import React, { useState, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getSeriesDetail } from "../utils/seriesApi";

import { Link } from "react-router-dom";

export const SeriesDetailComponent = () => {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [series, setSeries] = useState([]);
  const {seriesId} = useParams("seriesId");

  const {
      getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
      const fetchSeries = async() => {
          const token = await getAccessTokenSilently();
          try {
              const series = await getSeriesDetail(token, seriesId);
              setSeries(series);
              setIsLoaded(true);
          } catch(error) {
              setIsLoaded(false);
              setError(error);
          }
      }
      fetchSeries()
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="mb-5">
          <h1>{series.seriesId}</h1>
          <h4>{series.episodeCount} Episodes</h4>
        </div>

        <div>
          <Breadcrumb>
            <BreadcrumbItem tag="a" href="/series" >Kids' Shows</BreadcrumbItem>
            <BreadcrumbItem active>{seriesId}</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div className="result-block-container">
          
            <div className="result-block" data-testid="api-result">
              <div className="next-steps my-5">
                <Row className="d-flex justify-content-between">
                  {series.seasons.map((col, i) => (
                      <Col key={i} md={5} className="mb-4">
                      <h6 className="mb-3">
                        <Link to={{pathname: `/series/${seriesId}/season/${col.season}`}}>
                          <FontAwesomeIcon icon="link" className="mr-2" />
                          {col.season}
                        </Link>
                      </h6>
                      <p>{col.episodeCount} episodes</p>
                      </Col>
                  ))}
                  </Row>
              </div>
            </div>
          
        </div>
      </>
    );
  }  
};

export default withAuthenticationRequired(SeriesDetailComponent, {
    onRedirecting: () => <Loading />,
  });