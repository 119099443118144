const contentData = [{
    title: "Who am I?",
    link: "https://www.linkedin.com/in/ryanbreen",
    description:
      "My first job out of school was a startup named Porivo.  20 years later, it's still meaningful to me, but I've done some other stuff, too."
  },{
    title: "What was Porivo?",
    link: "http://web.archive.org/web/20000815092337/http://www.porivo.com/index.html",
    description:
      "A WaybackMachine look at what we did in our fun little start-up."
  }
];

export default contentData;
