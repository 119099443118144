
import { getConfig } from '../config'

const getSeriesList = async (token) => {
    const { apiOrigin = "https://transcode.porivo.io", origin } = getConfig();
    const response = await fetch(`${apiOrigin}/series`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    const seriesJson = await response.json();
    const series = seriesJson.sort((a, b) => a.series.localeCompare(b.series));
  
    return await series;
}

const getSeriesDetail = async (token, series) => {
    const { apiOrigin = "https://transcode.porivo.io", origin } = getConfig();
    const response = await fetch(`${apiOrigin}/series/${series}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    const seriesJson = await response.json();
    return await seriesJson;
}

const getSeasonDetail = async (token, series, season) => {
    const { apiOrigin = "https://transcode.porivo.io", origin } = getConfig();
    const response = await fetch(`${apiOrigin}/series/${series}/season/${season}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return await response.json();
}

const deleteEpisode = async (token, series, season, episodeId) => {
    const { apiOrigin = "https://transcode.porivo.io", origin } = getConfig();
    const response = await fetch(`${apiOrigin}/series/${series}/season/${season}/episode/${episodeId}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

const compact = async (token) => {
    const { apiOrigin = "https://transcode.porivo.io", origin } = getConfig();
    const response = await fetch(`${apiOrigin}/compact`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

const getInvalidEpisodes = async (token, series) => {
    const { apiOrigin = "https://transcode.porivo.io", origin } = getConfig();
    const response = await fetch(`${apiOrigin}/invalid-episodes`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    const invalidEpisodeJson = await response.json();
    return await invalidEpisodeJson;
}

export { getSeriesList, getSeriesDetail, getSeasonDetail, deleteEpisode, compact, getInvalidEpisodes };